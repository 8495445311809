<template>
  <h3>
    <v-text-field
      v-model="computedValue"
      :label="label"
      :rules="rules"
      :readonly="readonly"
      @input="value_change"
      hide-spin-buttons
      :key="dd"
      ref="input"
      filled
      outlined
      dense
    />
  </h3>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    label: {
      type: String,
    },
    rules: Array,
    readonly: Boolean,
  },
  computed: {},
  data() {
    return {
      dd: 100,
      computedValue: null,
    };
  },
  created() {
    if (this.value) {
      this.computedValue = this.formatCurrency(this.value);
    }
  },
  mounted() {},
  methods: {
    value_change(e) {
      if (e) this.computedValue = this.formatCurrency(e);
      this.dd++;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    formatCurrency(value) {
      let newValue;
      if (value) {
        newValue = this.parseCurrency(value);
        let [whole, fraction] = newValue.split(".");
        // converting whole to number as toLocaleString works with numbers only
        if (whole !== "") whole = +whole;
        // if user types a . as the first character
        if (whole === "" && fraction === "") {
          whole = 0;
        }

        if (fraction && fraction.length > 2) {
          // fraction can atmost have length 2
          fraction = fraction.substring(0, 2);
        }
        if (fraction) {
          newValue = whole.toLocaleString("fr-FR") + "." + fraction;
        } else if (fraction === "") {
          // fraction will be empty string when user enters something like 97.
          newValue = whole.toLocaleString("fr-FR") + ".";
        } else {
          newValue = whole.toLocaleString("fr-FR");
        }
        if (newValue === "") {
          this.$emit("input", "");
          return "";
        }

        this.$emit("input", this.parseCurrency(newValue));
      }
      return newValue;
    },
    parseCurrency(value) {
      let parsed;
      if (value) {
        parsed = value.toString();
        // removing everything except \d and \. (numbers and decimal)
        parsed = parsed.replace(/[^\d|.]/g, "");
      }
      return parsed;
    },
  },
};
</script>
